export class CategoryViewModel {
  public Id: string;
  public Code: string
  public Name: string;
  public CreatedBy: string;
  public CreationDate: string;
  public RecordStatus: Date;
  public UserName: Date;

}

export class CatlogViewModel {
  public Id: number;
  public FK_CatrgoryID: ItemViewModel;
  public Code: string
  public Name: string;
  public CreatedBy: string;
  public CreationDate: string;
  public RecordStatus: Date;
  public UserName: Date;
  public Abrvation: string;

}
export class longViewModel {
  constructor(id: number, name?: string) {
    this.Id = id;
    this.Name = name;
  }
  
  public Id: number;
  public Name: string;
}
export class ItemViewModel {
  constructor(id?: number, name?: string) {
    this.Id = id;
    this.Name = name;
  }
  
  public Id: number;
  public Name: string;
}
export class StringItemViewModel{
  constructor(id?: string, name?: string) {
    this.Id = id;
    this.Name = name;
  }
  
  public Id: string;
  public Name: string;
}
export class ItemDefiningViewModel {
  public Id: number;
  public Item_Type: string;
  public Code: string
  public RegistrationNo: string
  public Item_Name: string;
  public FK_ItemCategoryId: ItemViewModel;
  public Brand: string;
  public Model_Year: string;
  public Color: string;
  public FK_Associat_Account: ItemViewModel;
  public FK_Associat_Coustomer: ItemViewModel;
  public FK_Billing_Account: ItemViewModel;
  public FK_CreditAccount: ItemViewModel;
  public Size: string;
  public Measure: string;
  public Purchase_Price: number;
  public Sale_Price: number;
  public Description: string;
  public Qty: number;
  public Status: boolean;
  public Bike_Engg_Power: string;
  public Bike_Insured_By: string;
  public Bike_Salik: boolean;
  public Bike_Garage: string;
  public Mobile_Ram: string;
  public Mobile_InternalMemory: string;
  public Sim_Plan_Banifits: string;
  public Contract_Expire_Date: Date;
  public City: string;
  public Exp_Date: Date;
  public Remarks: string;
  public chassisNo: string;
  public engineNo: string;
  public IsAssigned: string;
}

export class Sale_PurchaseMastViewModel {
  public Id: number;
  public SP_Date: Date
  public FK_Dabit_Acc: ItemViewModel;
  public FK_Inv_Type: ItemViewModel;
  public Cargo: string;
  public Sup_TRN: string;
  public ContectPerson: string;
  public CPPhone: string;
  public CPEmail: string;
  public CPFex: string;
  public FB_No: string;
  public FK_Sup_Cradit_Acc: ItemViewModel;
  public Remarks: string;
  public Staus: string;
  public Inv_Amount = 0;
  public CreatedBy: string;
  public CreationDate: Date;
  public UpdatedBy: string;
  public UpdationDate: Date;
  public RecordStatus: Date;
  public SP_Items: Sale_PurchaseDetailViewModel[];
  public PaidAmount: number;
  public SP_Type: string;
  public PaymentType: string;
  public TermofPayment: string
  public file: any;
  public IsQRGenerate:boolean=false;
}

export class Sale_PurchaseDetailViewModel {
  public Id: number;
  public FK_Mast_Id: number
  public Item_Code: string;
  public Item_Name: string;
  public Item_Type: string;
  public FK_Item_Id: number;
  public Item_Detail: string;
  public Qty: number;
  public avaQty: number;
  public Detail: string;
  public AvalibelQty: number;
  public AvalibilityStatus: string;
  public UOM: string;
  public Rate: number;
  public VAT_Type: string;
  public VAT_Value: number;
  public Total: number;
  public CreationDate: Date;
  public CreatedBy: string;
  public UpdatedBy: string;
  public UpdationDate: Date;
  public RecordStatus: boolean;
  public VatAmount: number;
  public Batch: number;
  public SPD_ID: number;
}

export class StockAssignHistoryViewModel {
  public Id: number;
  public Item_Code: string;
  public Item_Name: string;
  public Item_Type: string;
  public Item_Batch: number;
  public SPD_ID: number;
  public FK_Employee: ItemViewModel;
  public FK_RentAgreement: ItemViewModel;
  public Issue_Date: Date;
  public FK_Item_Id: number;
  public Issue_Qty: number;
  public Return_Date: Date;
  public Remarks: string;
  public Return_Status: boolean;
  public Issued_By: string;
  public RecordStatus: boolean;
  public AssigingType: number;
  public IsRentAgreement: boolean;
  public FK_ItemCategoryId: number;
  public IssueDate: string;
}

export class Attachments {
  public Id: number;
  public FK_Item_Id: number;
  public FK_Account_Id: number;
  public FK_Employee_Id: number;
  public FK_Contrect_Id: number;

  public ExpiryDAte: Date;
  public DocName: string;
  public Path: string;
  public Remarks: string;
  public file: any;

}

export class OtherDocument {
  public Id: number;
  public FK_Item_Id: number;
  public FK_Employee_Id: number;
  public DocName: string;
  public Path: string;
  public Remarks: string;
  public file: any;
}

export class InstalmentVewModel {
  public Id: number;
  public FK_Account: ItemViewModel;
  public FK_Account_Cradit: ItemViewModel;
  public FK_From_Account: ItemViewModel;
  public FK_SP_Mast_ID: number;
  public FK_Rent_Mast_Id: number;
  public FK_Salary_Mast: number;
  public Amount: number;
  public ExpiryDAte: Date;
  public Mode: string;
  public ChacqNo: string;
  public ChacqPic: string;
  public Remarks: string;
  public file: any;
  public SP_Date: Date;
  public InstalmentType: number;
  public Type: string;
  public IsDayBook: boolean;
  public Adjustments?: InstalmentAdjustmentViewModel[];
  public Attachment: string;
  public excelFile:any;
}

export class InstalmentAdjustmentViewModel {
  public AdjustmentAmmount: number;
  public AdjustmentType: string;
  public AdjustmentAccountId: number;
}


export class VehicleOnRentViewModel {
  public Id: number;
  public FK_Suplier_Acc: ItemViewModel;
  public Fk_Item_Id: number;
  public Rent: number;
  public ReciveDate: Date;
  public ReturnDate: Date;
  public Remarks: string;
  public isReturn: boolean;
  public Item_Code: string;
  public Item_Name: string;
}

export class VehicleRentMastViewModel {
  public Id: number;
  public date: Date;
  public Fk_Expens_Account: ItemViewModel;
  public FK_Suplier_Account: ItemViewModel;
  public Remarks: string;
  public RentType: string;
  public FromDate: Date;
  public ToDate: Date;
  public Vat: number;
  public VatAmount: number;
  public RentAmount: number;
  public AmountPaid: number;
  public RentWithOutVat: number;

  public Total_Vehchel: number;
  public TotalDays: number;

  public InvList: VehicleRentDetailViewModel[];
  public CreationDate: Date;
  public TransactionDate: Date;

  public file: any;

}

export class VehicleRentDetailViewModel {
  public Id: number;
  public FK_Mast_Id: number;
  public FK_RentedVechiel_Id: ItemViewModel;
  public Days: number;
  public Rent: number;
  public RentPerDay: number;
  public ReciveDate: Date;
}

export class RentAgreementInvoiceMastViewModel {
  public Id: number;
  public InvoiceDate: Date;
  public Year: Date;
  public ServiceMonth:ItemViewModel;
  public FK_RevnueAccount: ItemViewModel;
  public FK_Customer: ItemViewModel;
  public Remarks: any;
  public RentType: string;
  public StartDate: Date;
  public EndDate: Date;
  public Total_Vehchel: number;
  public TotalDays: number;
  public RentInvoiceDetail: RentAgreementInvoiceDetailViewModel[];
  public CreationDate: Date;
  public file: any;

  public Vat: number;
  public VatAmount: number;
  public RentAmount: number;
  public AmountPaid: number;
  public RentWithOutVat: number;
}

export class RentAgreementInvoiceDetailViewModel {
  public Id: number;
  public FK_RentMast: number;
  public Fk_Agreement: ItemViewModel;
  public RefNo:string;
  public BikeNumber: string;
  public StartDate: Date;
  public EndDate: Date;
  public TotalDays: number;
  public Rent: number;
  public RentPerDay: number;
  public RentalCharge: number;
  public Vat : number;
  public Tottal: number;
  public ReciveDate: Date;
  public IsTempray:boolean;
}

export class DepreciationViewModel {
  public Id: number;
  public FK_ItemId: ItemViewModel;
  public ItemCode: string;
  public FK_StockAccount: ItemViewModel;
  public FK_ExpenseAccount: ItemViewModel;
  public FK_Client_Id: ItemViewModel;
  public CurrentValue: number;
  public DepreciationAmount: number;
  public NewValue: number;
  public Percentage: number;
  public Remarks: string;
  public TransactionDate: Date;
  public Batch : number;
  public Quantity : number;
  public TottalDepreciationAmount : number;
  public SPDID:any;
}

export enum EMonths
{
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}


export class QuotationSaveModel {
  public Id: number;
  public FK_ClientItemVM: ItemViewModel = new ItemViewModel();
  public FK_CustomerItemVM: ItemViewModel= new ItemViewModel();
  public FK_NoteItemVM: ItemViewModel= new ItemViewModel();
  public FK_SalesPersonItemVM: ItemViewModel= new ItemViewModel();
  public Subject: string;
  public QuoteNumber: string;
  public QuoteDate: Date;
  public ItemList: QuotationItemModel[];
  public SubTotal: number;
  public Vat: number;
  public Total: number;
}

export class QuotationItemModel{
  public Id: number;
  public Name: string;
  public Description: string;
  public Quantity: number;
  public Rate: number;
  public Tax: number;
  public TaxAmount: number;
  public Total: number;
}

export class QuotationFormModel{
  public FK_ClientItemVM: ItemViewModel;
  public FK_CustomerItemVM: ItemViewModel[];
  public FK_NoteItemVM: ItemViewModel[];
  public FK_SalesPersonItemVM: ItemViewModel[];
  public FK_ItemVM: ItemViewModel[];
  public QuotationNumbers: string[]
}